import './OrderCalendarContentsContainer.css';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import UnitContentsContainer from "../UnitContentsContainer/UnitContentsContainer";
import Calendar from "../Calendar/Calendar";
import TimeSelector from '../TimeSelector/TimeSelector';

import { selectDate, selectTime } from '../../functions/user';
import { saveUserInfo } from '../../_reducers/user';

export default function OrderCalendarContetnsContainer(props) {
    const dispatch = useDispatch();

    const timeSelectEvent = (time) => {
        selectTime(time).then((user) => dispatch(saveUserInfo(user)));
    }

    const user = useSelector(state => state.user);

    let timeSelector;
    if (props.isTimeNeeded) {
        timeSelector = 
            <div className='order-calendar-contents-container-time-selector-container'>
                <TimeSelector selectEvent={timeSelectEvent} 
                              year={props.year}
                              month={props.month}
                              day={props.day}/>
            </div>
    }

    const dateGuide = 
        <div className="order-calendar-contents-container-date-guide">
            <span className="order-calendar-contents-container-date">
                {props.year}년 {props.month}월 {props.day}일을 선택하셨어요.
            </span>
        </div>

    let timeGuide;
    if (props.isTimeNeeded) {
        if (props.minute > 0) {
            if (props.isStoreVisitNeeded) {
                timeGuide = 
                <div className="order-calendar-contents-container-time-guide">
                    <span className="order-calendar-contents-container-time">
                        {props.hour}시에 {props.minute}분에 방문해주세요.
                    </span>
                </div>
            } else {
                timeGuide = 
                <div className="order-calendar-contents-container-time-guide">
                    <span className="order-calendar-contents-container-time">
                        {props.hour}시에 {props.minute}분에 맞춰 배송해드릴게요.
                    </span>
                </div>
            }
        } else {
            if (props.isStoreVisitNeeded) {
                timeGuide = 
                <div className="order-calendar-contents-container-time-guide">
                    <span className="order-calendar-contents-container-time">
                        {props.hour}시에 방문해주세요.
                    </span>
                </div>
            } else {
                timeGuide = 
                <div className="order-calendar-contents-container-time-guide">
                    <span className="order-calendar-contents-container-time">
                        {props.hour}시에 맞춰 배송해드릴게요.
                    </span>
                </div>
            }
        } 
    }

    useEffect(() => {
        const [year, month, date] = props.startDate.split('-');
        selectDate(year,month,date).then((user) => {
            if (user) {
                dispatch(saveUserInfo(user));
            }
        });
        selectTime('12:00').then((user) => {
            if (user) {
                dispatch(saveUserInfo(user));
            }
        })
    }, []);

    const userDate = new Date(user.date.split('-')[0],user.date.split('-')[1]-1,user.date.split('-')[2]);
    const startDate = new Date(props.startDate.split('-')[0],props.startDate.split('-')[1]-1,props.startDate.split('-')[2]);
    const endDate = new Date(props.endDate.split('-')[0],props.endDate.split('-')[1]-1,props.endDate.split('-')[2]);
    if ((userDate < startDate) || (endDate < userDate)) {
        selectDate(props.startDate.split('-')[0],props.startDate.split('-')[1],props.startDate.split('-')[2]).then((user) => {
            if (user) {
                dispatch(saveUserInfo(user));
            }
        });
    }

    const calendarDayClickEvent = (year,month,date) => {
        selectDate(year,month,date).then((user) => {
            if (user) {
                dispatch(saveUserInfo(user));
            }
        })
        selectTime('12:00').then((user) => {
            if (user) {
                dispatch(saveUserInfo(user));
            }
        })
    }

    let isChristmasCakeIncluded = false;
    props.cart.map((item) => {
        console.log(item);
        if ((item.name === "스트로베리 스퀘어" && item.quantity > 0 && item.isChecked) ||
            (item.name === "부쉬 드 노엘" && item.quantity > 0 && item.isChecked) || 
            (item.name === "피스타치오 프레지에" && item.quantity > 0 && item.isChecked)) {
            isChristmasCakeIncluded = true;
        } 
    })

    let unselectableDateList = [];
    if (isChristmasCakeIncluded) {
        unselectableDateList = ['2024-12-24'];
    }

    return (
        <UnitContentsContainer title={props.title}>
            <div style={{'minHeight':'30px'}}></div>
            <Calendar 
                startDate={props.startDate} 
                endDate={props.endDate} 
                unselectableDayList={props.unselectableDayList}
                clickEvent={calendarDayClickEvent}
                unselectableDateList={unselectableDateList}/>
            {timeSelector}
            <div style={{'minHeight':'20px'}}></div>
            {dateGuide}
            <div style={{'minHeight':'5px'}}></div>
            {timeGuide}
            <div style={{'minHeight':'30px'}}></div>
            <div style={{'width':'100%','minHeight':'1px','backgroundColor':'#eee'}}></div>
        </UnitContentsContainer>
    )
}

OrderCalendarContetnsContainer.defaultProps = {
    title: '픽업날짜',
    year: 2023,
    month: 8,
    day: 15,
    hour: 15,
    minute: 0,
    startDate: String(new Date().getFullYear())+'-'+String(new Date().getMonth()+1)+'-'+String(new Date().getDate()),
    endDate: String(new Date().getFullYear())+'-'+String(new Date().getMonth()+1)+'-'+String(new Date().getDate() + 7),
    unselectableDayList: [1,4],
    isTimeNeeded: true,
    isStoreVisitNeeded: false,
    cart: [],
}