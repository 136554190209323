import '../default.css';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import Header from "../components/Header/Header";
import OrderSheetContentsContainer from '../components/OrderSheetContentsContainer/OrderSheetContentsContainer';
import CustomerInfoContentsContainer from '../components/CustomerInfoContentsContainer/CustomerInfoContentsContainer';
import ShippingContentsContainer from '../components/ShippingContentsContainer/ShippingContentsContainer';
import OrderCalendarContetnsContainer from '../components/OrderCalendarContentsContainer/OrderCalendarContentsContainer';
import OrderButton from '../components/OrderButton/OrderButton';

import { authUser } from '../functions/auth';
import { saveUserInfo, login, logout } from '../_reducers/user';
import { orderWithTossPayments } from '../functions/order';

export default function OrderView(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const user = useSelector(state => state.user);

    useEffect(() => {
        authUser().then((user) => {
            if (user) {
                dispatch(login());
                dispatch(saveUserInfo(user));
            } else {
                dispatch(logout());
                navigate('/');
            }
        })
    }, []);

    let cart;
    switch (props.status) {
        case '배송주문':
            cart = user.deliveryCart;
            break;
        case '선물주문':
            cart = user.presentCart;
            break;
        case '픽업주문':
            cart = user.pickupCart;
            break;
        default:
            cart = user.deliveryCart;
            break;
    }

    let itemPickupStartDate;
    let itemPickupEndDate;
    if (props.status === '픽업주문') {
        cart.map((cartItem) => {
            if (cartItem.isChecked) {
                if (cartItem.pickupStartDate) itemPickupStartDate = cartItem.pickupStartDate;
                if (cartItem.pickupEndDate) itemPickupEndDate = cartItem.pickupEndDate;
            }
        })
    }

    let CalendarBox;
    let pickupDisableDays;
    let pickupStartDate;
    let pickupEndDate;
    if (itemPickupStartDate && itemPickupEndDate) {
        pickupDisableDays = [1,4];
        pickupStartDate = new Date(itemPickupStartDate.split('-')[0],itemPickupStartDate.split('-')[1]-1, itemPickupStartDate.split('-')[2]);
        pickupEndDate = new Date(itemPickupEndDate.split('-')[0],itemPickupEndDate.split('-')[1]-1, itemPickupEndDate.split('-')[2]);
    } else {
        pickupDisableDays = [1,4];
        const pickupDateStartOffset = 2;
        const pickupDateEndOffset = 21;
        pickupStartDate = new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate() + pickupDateStartOffset);
        for (let day = 0; day < 7 ; day++) {
            if (pickupDisableDays.includes(pickupStartDate.getDay())) {
                pickupStartDate.setDate(pickupStartDate.getDate() + 1);
            }
        }
        pickupEndDate = new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate() + pickupDateEndOffset);
        for (let day = 0; day < 7 ; day++) {
            if (pickupDisableDays.includes(pickupEndDate.getDay())) {
                pickupEndDate.setDate(pickupEndDate.getDate() + 1);
            }
        }
    }

    let itemPresentStartDate;
    let itemPresentEndDate;
    if (props.status === '선물주문') {
        cart.map((cartItem) => {
            if (cartItem.isChecked) {
                if (cartItem.presentStartDate) itemPresentStartDate = cartItem.presentStartDate;
                if (cartItem.presentEndDate) itemPresentEndDate = cartItem.presentEndDate;
            }
        })
    }

    let presentDisableDays;
    let presentStartDate;
    let presentEndDate;
    if (itemPresentStartDate && itemPresentEndDate) {
        presentDisableDays = [];
        presentStartDate = new Date(itemPresentStartDate.split('-')[0],itemPresentStartDate.split('-')[1]-1, itemPresentStartDate.split('-')[2]);
        presentEndDate = new Date(itemPresentEndDate.split('-')[0],itemPresentEndDate.split('-')[1]-1, itemPresentEndDate.split('-')[2]);
    } else {
        presentDisableDays = [];
        const presentDateStartOffset = 7;
        const presentDateEndOffset = 30;
        presentStartDate = new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate() + presentDateStartOffset);
        for (let day = 0; day < 7 ; day++) {
            if (presentDisableDays.includes(presentStartDate.getDay())) {
                presentStartDate.setDate(presentStartDate.getDate() + 1);
            }
        }
        presentEndDate = new Date(new Date().getFullYear(),new Date().getMonth(), new Date().getDate() + presentDateEndOffset);
        for (let day = 0; day < 7 ; day++) {
            if (presentDisableDays.includes(presentEndDate.getDay())) {
                presentEndDate.setDate(presentEndDate.getDate() + 1);
            }
        }
    }

    if (props.status === '픽업주문') {
        CalendarBox =
            <OrderCalendarContetnsContainer
                title='픽업날짜'
                isTimeNeeded={true}
                isStoreVisitNeeded={true}
                startDate={String(pickupStartDate.getFullYear())+'-'+String(pickupStartDate.getMonth()+1)+'-'+String(pickupStartDate.getDate())}
                endDate={String(pickupEndDate.getFullYear())+'-'+String(pickupEndDate.getMonth()+1)+'-'+String(pickupEndDate.getDate())}
                unselectableDayList={pickupDisableDays}
                year={user.date.split('-')[0]}
                month={user.date.split('-')[1]}
                day={user.date.split('-')[2]}
                hour={user.time.split(':')[0]}
                minute={user.time.split(':')[1]}
                cart={user.pickupCart}/>
    } else if (props.status === '선물주문') {
        CalendarBox =
            <OrderCalendarContetnsContainer
                title='퀵 배송 날짜'
                isTimeNeeded={true}
                isStoreVisitNeeded={false}
                startDate={String(presentStartDate.getFullYear())+'-'+String(presentStartDate.getMonth()+1)+'-'+String(presentStartDate.getDate())}
                endDate={String(presentEndDate.getFullYear())+'-'+String(presentEndDate.getMonth()+1)+'-'+String(presentEndDate.getDate())}
                unselectableDayList={presentDisableDays}
                year={user.date.split('-')[0]}
                month={user.date.split('-')[1]}
                day={user.date.split('-')[2]}
                hour={user.time.split(':')[0]}
                minute={user.time.split(':')[1]}
                cart={user.presentCart}/>
    }

    

    let totalPrice = 0;
    let itemCount = 0;
    let orderName;
    cart.map((item) => {
        if (item.isChecked) {
            itemCount ++;
            if (item.option.name && item.options) {
                if (item.options.length > 0) {
                    totalPrice = totalPrice + item.option.price * item.quantity;    
                    if (!orderName) {
                        orderName = item.name + item.option.name;
                    }
                } else {
                    totalPrice = totalPrice + item.price * item.quantity; 
                    if (!orderName) {
                        orderName = item.name;
                    }   
                }
            } else {
                totalPrice = totalPrice + item.price * item.quantity;
                if (!orderName) {
                    orderName = item.name + item.option.name;
                }
            }
        }
    })
    if (itemCount > 1) {
        orderName = orderName + '외 ' + String(itemCount - 1) + '건';
    }
    if (props.status === '배송주문') {
        if (totalPrice < 50000) totalPrice = totalPrice + 4000;
    }
    if (props.status === '선물주문') totalPrice = totalPrice + 70000;
    const orderButtonText = totalPrice.toLocaleString() + '원 결제하기';

    let cartItemNumber;
    if (user.deliveryCart && user.pickupCart && user.presentCart) {
        cartItemNumber = user.deliveryCart.length+user.pickupCart.length+user.presentCart.length;
    } else {
        cartItemNumber = 0;
    }

    let shippingContents;
    if ((props.status === '배송주문') || (props.status === '선물주문')) {
        shippingContents = 
            <div style={{'width':'100%','maxWidth':'600px','paddingBottom':'30px'}}>
                <ShippingContentsContainer shippingList={user.shippings} activatedIndex={user.shippingIndex} userId={user.id}/>
            </div>
    }

    let isPhoneNumberUpdateNeeded = false;
    if ((user.phone === '010-0000-0000') || (user.phone === '01000000000')) {
        isPhoneNumberUpdateNeeded = true;
    }

    let orderButton;
    if (isPhoneNumberUpdateNeeded) {
        orderButton =
            <OrderButton
                text='연락처를 입력해주세요.'
                type={'not-fixed-type'}
                backgroundColor='#aaa'
                borderColor='#aaa'/>
    } else {
        orderButton =
            <OrderButton
                text={orderButtonText} 
                type={'not-fixed-type'}
                clickEvent={orderWithTossPayments}
                clickEventInput={
                    {
                        userId: user.id,
                        totalPrice: totalPrice,
                        orderName: orderName,
                        customerName: user.name,
                        orderType: window.location.pathname.split('/')[2],
                    }}/>
    }

    return (
        <div className='page padding-bottom-minimized'>
            <Header isLogined={user.isLogined} name={user.name} cartItemNumber={cartItemNumber} userClass={user.class}/>
            <div className='contents-container'>
                <div className='contents'>
                    <div style={{'minHeight':'30px'}}></div>
                    <OrderSheetContentsContainer items={cart} status={props.status}/>
                    <div style={{'minHeight':'30px'}}></div>
                    <CustomerInfoContentsContainer name={user.name} phone={user.phone} email={user.email}/>
                    <div style={{'minHeight':'30px'}}></div>
                    {shippingContents}
                    {CalendarBox}
                    <div style={{'minHeight':'30px'}}></div>
                    {orderButton}
                </div>
            </div>
        </div>
    )
}

OrderView.defaultProps = {
    status: '배송주문',
}