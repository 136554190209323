import './OrderHistoryBox.css';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import UnitBoxContainer from '../UnitBoxContainer/UnitBoxContainer';
import OrderHistoryDetailModal from '../OrderHistoryDetailModal/OrderHistoryDetailModal';
import OrderHistoryDetailMobileModal from '../OrderHistoryDetailMobileModal/OrderHistoryDetailMobileModal';
import DeleteCheckModal from '../DeleteCheckModal/DeleteCheckModal';
import OrderPickupTimeChangeModal from '../OrderPickupTimeChangeModal/OrderPickupTimeChangeModal';
import OrderPickupTimeChangeMobileModal from '../OrderPickupTimeChangeMobileModal/OrderPickupTimeChangeMobileModal';

import { cancelOrder } from '../../functions/order';
import { saveUserInfo } from '../../_reducers/user';

export default function OrderHistoryBox(props) {
    const dispatch = useDispatch();
    const [isMobileModalOn, setMobileModalOn] = useState(false);
    const [isPickupTimeChangeMobileModalOn, setPickupTimeChangeMobileModalOn] = useState(false);

    let year;
    let month;
    let day;
    let PickupDate;
    if (props.orderType === 'pickup') {
        PickupDate = 
            <div className='order-history-box-info'>
                <span className='order-history-box-info-title'>픽업 날짜</span>
                <span className='order-history-box-info-value'>{props.pickupDate} {props.pickupTime}</span>
            </div>
       year = props.pickupDate.split('-')[0];
       month = props.pickupDate.split('-')[1];
       day = props.pickupDate.split('-')[2];
    }

    let PresentDate;
    if (props.orderType === 'present') {
        PresentDate = 
            <div className='order-history-box-info'>
                <span className='order-history-box-info-title'>퀵 배송 날짜</span>
                <span className='order-history-box-info-value'>{props.presentDate} {props.presentTime}</span>
            </div>
        year = props.presentDate.split('-')[0];
        month = props.presentDate.split('-')[1];
        day = props.presentDate.split('-')[2];
    }

    let shipping;
    if (props.orderType === 'delivery') {
        shipping = 
            <div className='order-history-box-info'>
                <span className='order-history-box-info-title'>배송지</span>
                <span className='order-history-box-info-value'>{props.shipping.basicAddress} {props.shipping.detailAddress}</span>
            </div>
    }

    let itemSummary;
    let representativeName;
    if (props.menus) {
        if (props.menus.length) {
            if (props.menus.option) {
                if (props.menus.option.name) {
                    representativeName = props.menus[0].name + ' ' + props.menus[0].option.name;
                } else {
                    representativeName = props.menus[0].name;
                }
            } else {
                representativeName = props.menus[0].name;
            }
        } else {
            representativeName = props.menus[0].name;
        }
    } else {
        representativeName = props.menus[0].name;
    }

    const [orderTime, ] = props.createdAt.split('T');
    const [orderYear, orderMonth, orderDate] = orderTime.split('-');

    if (props.menus.length > 1) {
        itemSummary = representativeName + ' 외 ' + String(props.menus.length - 1) + '건';
    } else {
        itemSummary = representativeName;
    }

    const openMobileModal = () => {
        setMobileModalOn(true);
    }

    const closeMobileModal = () => {
        setMobileModalOn(false);
    }

    const openPickupTimeChangeMobileModal = () => {
        setPickupTimeChangeMobileModalOn(true);
    }

    const closePickupTimeChangeMobileModal = () => {
        setPickupTimeChangeMobileModalOn(false);
    }

    const showOrderHistoryDetailModal = () => {
        if (window.innerWidth > 700) {
            const OrderHistoryDetailModal = document.getElementById('order-history-detail-modal-' + props.id);
            OrderHistoryDetailModal.showModal();
        } else {
            openMobileModal();
        }
    }
    
    const openOrderCancelCheckModal = () => {
        const OrderCancelCheckModal = document.getElementById('order-cancel-modal-' + props.id) ;
        OrderCancelCheckModal.showModal();
    }

    const showOrderPickupTimeChangeModal = () => {
        if (window.innerWidth > 700) {
            const OrderPickupTimeChangeModal = document.getElementById('order-pickup-time-change-modal-' + props.id);
            OrderPickupTimeChangeModal.showModal();
        } else {
            openPickupTimeChangeMobileModal();
        }
    }

    let CancelButton;
    if (props.orderStatus === '결제완료') {
        CancelButton =
            <button className='order-history-box-small-button' onClick={() => openOrderCancelCheckModal()}>
                <span className='order-history-box-small-button-text'>취소하기</span>
            </button>
    }

    let ShippingEditButton;
    if (((props.orderStatus === '결제완료') || (props.orderStatus === '주문확인')) && (props.orderType === 'delivery')) {
        ShippingEditButton = 
            <button className='order-history-box-small-button'>
                <span className='order-history-box-small-button-text'>배송지변경</span>
            </button>
    }

    let PickupTimeEditButton;
    let dateDiff = 0;
    if (props.orderType === 'pickup') {
        const pickupDate = new Date(props.pickupDate);
        const todayDate = new Date();
        dateDiff = (pickupDate - todayDate)/(1000*60*60*24);
    }
    if (((props.orderStatus === '결제완료') || (props.orderStatus === '주문확인') || (props.orderStatus === '상품준비중')) && (props.orderType === 'pickup') && (dateDiff > 2)) {
        PickupTimeEditButton = 
            <button className='order-history-box-small-button' onClick={() => showOrderPickupTimeChangeModal()}>
                <span className='order-history-box-small-button-text'>시간변경</span>
            </button>
    }

    const orderHistoryBoxSmallButtons =
        <div className='order-history-box-small-buttons'>
            {/* {ShippingEditButton} */}
            {PickupTimeEditButton}
            {CancelButton}
            <button className='order-history-box-small-button' onClick={() => showOrderHistoryDetailModal()}>
                <span className='order-history-box-small-button-text'>상세보기</span>
            </button>
        </div>

    const orderCancelEvent = async() => {
        cancelOrder(props.id).then(user => {
            dispatch(saveUserInfo(user));
        })
    }

    return (
        <UnitBoxContainer>
            <div className='order-history-box'>
                <img className='order-history-box-image'
                     src={props.image}
                     alt='thumbnail'></img>
                <div className='order-history-box-infos'>
                    <div className='order-history-box-info'>
                        <span className='order-history-box-info-title'>주문한 날짜</span>
                        <span className='order-history-box-info-value'>{orderYear}.{orderMonth}.{orderDate}</span>
                    </div>
                    <div className='order-history-box-info'>
                        <span className='order-history-box-info-title'>주문한 상품</span>
                        <span className='order-history-box-info-value'>{itemSummary}</span>
                    </div>
                    <div className='order-history-box-info'>
                        <span className='order-history-box-info-title'>결제 금액</span>
                        <span className='order-history-box-info-value'>{props.payedMoney.toLocaleString()}원</span>
                    </div>
                    {PickupDate}
                    {PresentDate}
                    {shipping}
                    <div className='order-history-box-info'>
                        <span className='order-history-box-info-title'>주문 상태</span>
                        <span className='order-history-box-info-value'>{props.orderStatus}</span>
                    </div>
                </div>
            </div>
            {orderHistoryBoxSmallButtons}
            <OrderHistoryDetailModal
                id={props.id}
                menus={props.menus}/>
            <OrderHistoryDetailMobileModal
                isOn={isMobileModalOn}
                closeEvent={closeMobileModal}
                menus={props.menus}/>
            <DeleteCheckModal
                id={'order-cancel-modal-'+props.id}
                text='취소하시겠습니까?'
                cancelText='돌아가기'
                deleteText='주문취소'
                deleteEvent={orderCancelEvent}/>
            <OrderPickupTimeChangeModal
                id={props.id}
                year={year}
                month={month}
                day={day}/>
            <OrderPickupTimeChangeMobileModal
                id={props.id}
                isOn={isPickupTimeChangeMobileModalOn}
                closeEvent={closePickupTimeChangeMobileModal}
                year={year}
                month={month}
                day={day}/>
        </UnitBoxContainer>
    )
}

OrderHistoryBox.defaultProps = {
    id: '000',
    orderType: 'pickup',
    menus: [{name:'바스크치즈케이크',price:42000,quantity:1},{name:'골드키위요거트생크림케이크',price:45000,quantity:2}],
    payedMoney: 42000,
    pickupDate: '2023-12-31',
    pickupTime: '15:00',
    presentDate: '2023-12-31',
    presentTime: '15:00',
    orderStatus: '결제완료',
    createdAt: '2023-08-15T15:00:33'
}