import './OrderSummary.css';

export default function OrderSummary(props) {
    let orderNumber = 0;
    let totalSales = 0;
    if (props.orders) {
        if (props.orders.length) {
            props.orders.map((order) => {
                if (order.status !== '결제취소') {
                    orderNumber++;
                    totalSales = totalSales + order.orderPrice;
                }
            })
        }
    }

    let menus = [];
    
    props.orders.map((order) => {
        if (order.menus && order.status !== '결제취소') {
            if (order.menus.length) {
                order.menus.map((menu) => {
                    if (menu.isChecked) {
                        if (menus.length) {
                            const itemIndex = menus.findIndex((item) => item.name === menu.name);
                            if (itemIndex < 0) {
                                let newItem = {
                                    "name": menu.name,
                                    "quantity": menu.quantity
                                }
                                menus.push(newItem);
                            } else {
                                menus[itemIndex].quantity = menus[itemIndex].quantity + menu.quantity;
                            }
                        } else {
                            let newItem = {
                                "name": menu.name,
                                "quantity": menu.quantity
                            }
                            menus.push(newItem);
                        }
                    }
                })
            }
        }
    })

    
    const orderMenuSummaryItems = menus.map((menu) => {
        return (
            <div className='order-summary-menus-item' key={menu.name}>
                <span className='order-summary-menus-item-text'>{menu.name}</span>
                <span className='order-summary-menus-item-text'>{menu.quantity}</span>
            </div>
        )
    })

    let orderNumberSummary;
    let orderMenusSummary;
    if (props.orders) {
        if (orderNumber) {
            orderNumberSummary = 
                <h3 className='order-summary-number'>매출은 {totalSales.toLocaleString()}원, 주문은 {orderNumber}건 입니다.</h3>
            orderMenusSummary = 
                <div className='order-summary-menus'>
                    {orderMenuSummaryItems}
                </div>
        }
    }

    return (
        <div className='order-summary'>
            {orderNumberSummary}
            {orderMenusSummary}
        </div>
    )
}

OrderSummary.defaultProps = {
    orders: [
        {
            "customer": {
                "_id": "65006544db022fdf387dfee8",
                "name": "jw",
                "phone": "010-1111-1111",
                "email": "leejiwon@kakao.com"
            },
            "_id": "662e4160375f1670e4159177",
            "menus": [
                {
                    "menuClass": {
                        "_id": "662e377e375f1670e4158a6c",
                        "name": "5월 가정의 달"
                    },
                    "name": "카네이션 프로마쥬롤",
                    "price": 43000,
                    "tag": "가정의달",
                    "stock": 100,
                    "orderType": "pickup",
                    "quantity": 1,
                    "option": {
                        "name": "basic",
                        "price": 0
                    },
                    "isChecked": true,
                    "imageUrl": "https://madinbakery.s3.ap-northeast-2.amazonaws.com/menu/2024_flower_cake.png",
                    "detailImageUrl": [
                        "https://madinbakery.s3.ap-northeast-2.amazonaws.com/menuDetail/2024_flower_cake_detail_00.png",
                        "https://madinbakery.s3.ap-northeast-2.amazonaws.com/menuDetail/2024_flower_cake_detail_01.png",
                        "https://madinbakery.s3.ap-northeast-2.amazonaws.com/menuDetail/2024_flower_cake_detail_02.png"
                    ],
                    "intro": "픽업일은 5월 4, 5, 7, 8일이에요. 5월 6일로 예약 시 별도 안내 없이 취소됩니다.",
                    "options": [],
                    "minimumOrderQuantity": 1,
                    "gradients": [],
                    "detail": "최근 매장에서 가장 인기있는 프로마쥬롤에 카네이션을 더했습니다. 약 13cm 길이의 롤케이크로 3-4인이 먹기 적당해요 :) 케이크는 세련된 투명 케이크 박스에 담기며, 고급스러운 카네이션 엽서와 리본초가 함께 구성됩니다. 예쁜 카네이션이 장식된 맛있는 케이크로 감사한 마음을 전달해보세요.",
                    "purchaseable": true,
                    "pickupStartDate": "2024-05-04",
                    "pickupEndDate": "2024-05-08",
                    "_id": "662e3810375f1670e4158afa",
                    "createdAt": "2024-04-28T11:50:40.612Z",
                    "updatedAt": "2024-04-28T11:50:40.612Z",
                    "__v": 0
                }
            ],
            "mileageUse": 0,
            "pickupDate": "2024-05-07",
            "pickupTime": "18:30",
            "type": "pickup",
            "status": "결제취소",
            "orderPrice": 43000,
            "payedMoney": 43000,
            "orderId": "20240428_212954_65006544db022fdf387dfee8",
            "receipt": "https://dashboard.tosspayments.com/receipt/redirection?transactionId=madin2024042821295790OI4&ref=PX",
            "paymentKey": "madin2024042821295790OI4",
            "createdAt": "2024-04-28T12:30:24.368Z",
            "updatedAt": "2024-04-28T12:31:33.689Z",
            "__v": 0
        },
        {
            "customer": {
                "_id": "657da7d7c31cfa295a5ca031",
                "name": "이정선",
                "phone": "010-3893-51",
                "email": "dlwjdtjs0111@gmail.com"
            },
            "_id": "662e4e81375f1670e4159ad8",
            "menus": [
                {
                    "menuClass": {
                        "_id": "662e377e375f1670e4158a6c",
                        "name": "5월 가정의 달"
                    },
                    "name": "카네이션 프로마쥬롤",
                    "price": 43000,
                    "tag": "가정의달",
                    "stock": 100,
                    "orderType": "pickup",
                    "quantity": 1,
                    "option": {
                        "name": "basic",
                        "price": 0
                    },
                    "isChecked": true,
                    "imageUrl": "https://madinbakery.s3.ap-northeast-2.amazonaws.com/menu/2024_flower_cake.png",
                    "detailImageUrl": [
                        "https://madinbakery.s3.ap-northeast-2.amazonaws.com/menuDetail/2024_flower_cake_detail_00.png",
                        "https://madinbakery.s3.ap-northeast-2.amazonaws.com/menuDetail/2024_flower_cake_detail_01.png",
                        "https://madinbakery.s3.ap-northeast-2.amazonaws.com/menuDetail/2024_flower_cake_detail_02.png"
                    ],
                    "intro": "픽업일은 5월 3, 4, 5, 7, 8일이에요. 5월 6일로 예약 시 별도 안내 없이 취소됩니다.",
                    "options": [],
                    "minimumOrderQuantity": 1,
                    "gradients": [],
                    "detail": "최근 매장에서 가장 인기있는 프로마쥬롤에 카네이션을 더했습니다. 약 13cm 길이의 롤케이크로 3-4인이 먹기 적당해요 :) 케이크는 세련된 투명 케이크 박스에 담기며, 고급스러운 카네이션 엽서와 리본초가 함께 구성됩니다. 예쁜 카네이션이 장식된 맛있는 케이크로 감사한 마음을 전달해보세요.",
                    "purchaseable": true,
                    "pickupStartDate": "2024-05-03",
                    "pickupEndDate": "2024-05-08",
                    "_id": "662e3810375f1670e4158afa",
                    "createdAt": "2024-04-28T11:50:40.612Z",
                    "updatedAt": "2024-04-28T11:50:40.612Z",
                    "__v": 0
                }
            ],
            "mileageUse": 0,
            "pickupDate": "2024-05-08",
            "pickupTime": "18:30",
            "type": "pickup",
            "status": "픽업완료",
            "orderPrice": 43000,
            "payedMoney": 43000,
            "orderId": "20240428_222534_657da7d7c31cfa295a5ca031",
            "receipt": "https://dashboard.tosspayments.com/receipt/redirection?transactionId=madin20240428222537TQ0j7&ref=PX",
            "paymentKey": "madin20240428222537TQ0j7",
            "createdAt": "2024-04-28T13:26:25.575Z",
            "updatedAt": "2024-05-08T05:05:59.101Z",
            "__v": 0
        },
        {
            "customer": {
                "_id": "654762be84118ac4563378c4",
                "name": "김은혜",
                "phone": "010-4074-7018",
                "email": "sy9800j@kakao.com"
            },
            "_id": "662e51cb375f1670e4159c22",
            "menus": [
                {
                    "menuClass": {
                        "_id": "662e377e375f1670e4158a6c",
                        "name": "5월 가정의 달"
                    },
                    "name": "카네이션 프로마쥬롤",
                    "price": 43000,
                    "tag": "가정의달",
                    "stock": 100,
                    "orderType": "pickup",
                    "quantity": 1,
                    "option": {
                        "name": "basic",
                        "price": 0
                    },
                    "isChecked": true,
                    "imageUrl": "https://madinbakery.s3.ap-northeast-2.amazonaws.com/menu/2024_flower_cake.png",
                    "detailImageUrl": [
                        "https://madinbakery.s3.ap-northeast-2.amazonaws.com/menuDetail/2024_flower_cake_detail_00.png",
                        "https://madinbakery.s3.ap-northeast-2.amazonaws.com/menuDetail/2024_flower_cake_detail_01.png",
                        "https://madinbakery.s3.ap-northeast-2.amazonaws.com/menuDetail/2024_flower_cake_detail_02.png"
                    ],
                    "intro": "픽업일은 5월 3, 4, 5, 7, 8일이에요. 5월 6일로 예약 시 별도 안내 없이 취소됩니다.",
                    "options": [],
                    "minimumOrderQuantity": 1,
                    "gradients": [],
                    "detail": "최근 매장에서 가장 인기있는 프로마쥬롤에 카네이션을 더했습니다. 약 13cm 길이의 롤케이크로 3-4인이 먹기 적당해요 :) 케이크는 세련된 투명 케이크 박스에 담기며, 고급스러운 카네이션 엽서와 리본초가 함께 구성됩니다. 예쁜 카네이션이 장식된 맛있는 케이크로 감사한 마음을 전달해보세요.",
                    "purchaseable": true,
                    "pickupStartDate": "2024-05-03",
                    "pickupEndDate": "2024-05-08",
                    "_id": "662e3810375f1670e4158afa",
                    "createdAt": "2024-04-28T11:50:40.612Z",
                    "updatedAt": "2024-04-28T11:50:40.612Z",
                    "__v": 0
                }
            ],
            "mileageUse": 0,
            "pickupDate": "2024-05-03",
            "pickupTime": "12:00",
            "type": "pickup",
            "status": "픽업완료",
            "orderPrice": 43000,
            "payedMoney": 43000,
            "orderId": "20240428_223946_654762be84118ac4563378c4",
            "receipt": "https://dashboard.tosspayments.com/receipt/redirection?transactionId=madin202404282239482zeS2&ref=PX",
            "paymentKey": "madin202404282239482zeS2",
            "createdAt": "2024-04-28T13:40:27.173Z",
            "updatedAt": "2024-05-03T05:05:48.118Z",
            "__v": 0
        }
    ]
}